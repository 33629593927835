import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// loads the Icon plugin
UIkit.use(Icons);

navigator.serviceWorker.register(
    new URL('sw.js', import.meta.url),
    {type: 'module'}
);
